import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSessions(ctx,  id  ) {
      console.log("lectures with id Fetching");
      console.log(id.value);
      const courseg=id.value;

      console.log('---');
    
      return new Promise((resolve, reject) => {
        axios
        .get(`/lms/admin/course/getalllectures/${courseg}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCourseInfo(ctx, { id }) {
      console.log("update course  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

   
    addCourse(ctx, userData) {

      console.log("course Create Lecture triggered");
      console.log(userData);
   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/createlecture', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reOrderLessons(ctx, userData) {

      console.log("Re order lecture triggered");
      console.log(userData);
  
   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updatelecturerorders', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
